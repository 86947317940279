import React from 'react'
import Typography from '@material-ui/core/Typography'
const PageNotFound = ({ location }) => (
  <div>
    <Typography variant='subtitle1'>
      Page not found for <code>{location.pathname}</code>
    </Typography>
  </div>
)

export default PageNotFound
